import React, { Component } from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby'
import { styles } from '../../../utils'

export default class NavbarLinks extends Component {
  state = {
    links: [
      {
        id: 0,
        path: '/projects/',
        name: 'projects'
      },
      {
        id: 1,
        path: '/teams',
        name: 'teams'
      },
      {
        id: 2,
        path: '/docs',
        name: 'docs'
      },
      {
        id: 3,
        path: '/news',
        name: 'news'
      },
      {
        id: 4,
        path: '/contact/',
        name: 'contact'
      },
    ]
  }
  render() {
    // console.log(this.props.navbarOpen)
    return (
      <LinkWrapper open={this.props.navbarOpen}>
        {
          this.state.links.map(item => {
            return (
              <li key={item.id} >
                <Link to={item.path} className="nav-link">
                  {item.name}
                </Link>
              </li>
            )
          })
        }
      </LinkWrapper>
    )
  }
}

const LinkWrapper = styled.ul`
  li {
    list-style-type: none;
  }
  .nav-link {
    display: block;
    text-decoration: none;
    padding: 0.5rem 1rem 0.5rem 1rem;
    color:${styles.colors.mainGrey};
    //background: ${styles.colors.mainGreen};
    font-weight: 700;
    font-size: 0.8rem;
    text-transform: capitalize;
    cursor: pointer;
    ${styles.transDefault};
    &:hover{
      background: ${styles.colors.mainGrey};
      color:${styles.colors.mainGreen};
      padding:0.5rem 1rem 0.5rem 1.3rem;
    }
  }
  height:${props => (props.open ? '168px' : '0px')};
  overflow: hidden;
  ${styles.transObject({ time: '1s' })};
  @media (min-width:786px) {
    height: auto;
    display: flex;
    margin: 0 auto;
    //background: ${styles.colors.mainGreen};
    .nav-link:hover {
      background:${styles.colors.mainWhite};
      padding: 0.5rem 1rem 0.5rem 1rem;
    }
  }
`
