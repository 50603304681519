import React from 'react'
import styled from 'styled-components'
import img from '../images/bg/homeBg.jpg'

function HomeHeader({ img, children }) {
  return <IndexHeader img={img}>
    {children}
  </IndexHeader>
}
function PageHeader({ img, children }) {
  return <DefaultHeader img={img}>
    {children}
  </DefaultHeader>
}

const IndexHeader = styled.header`
min-height: calc(100vh - 77.8px);
background: linear-gradient(rgba(0,0,0,0.3), rgba(0,0,0,0.3)), url(${props => props.img}) center/cover fixed no-repeat;
display: flex;
justify-content: center;
align-items: center;
`
const DefaultHeader = styled(IndexHeader)`
min-height: 60vh;
`
HomeHeader.defaulfProps = {
  img: img,
}
PageHeader.defaulfProps = {
  img: img,
}
export { HomeHeader, PageHeader }
