import React, { Component } from 'react'
import { Link } from 'gatsby'
import logo from '../../../images/sb-logo.svg'
import { FaAlignRight } from 'react-icons/fa'
import styled from 'styled-components'
import { styles } from '../../../utils'

export default class NavbarHeader extends Component {
  render() {
    const { handleNavbar } = this.props
    return (
      <HeaderWrapper>
        <Link to="/">
          <img src={logo} alt="company name" height="60px" />
        </Link>
        <FaAlignRight className="toggle-icon" onClick={() => { handleNavbar() }}></FaAlignRight>
      </HeaderWrapper>
    )
  }
}

const HeaderWrapper = styled.div`
  padding: 0.4rem 1rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: ${styles.colors.mainWhite};
  max-width: 1100px;
  .toggle-icon{
    font-size:1.75rem;
    color: ${styles.colors.mainYellow};
    color: #444;
    cursor:pointer;
  }
  @media (min-width:768px) {
    .toggle-icon{
      display: none;
    }
  }
`

