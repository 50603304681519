/**
 * Layout component that queries for data
 * with Gatsby's StaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/static-query/
 */

import React, { Fragment } from "react"
import PropTypes from "prop-types"
import { createGlobalStyle } from 'styled-components';
import Navbar from '../components/globals/navbar'
import Footer from '../components/globals/Footer'
// import "./layout.css"

const Layout = ({ children }) => {
  return <Fragment>
    <GlobalStyle></GlobalStyle>
    <Navbar />
    {children}
    <Footer />
  </Fragment>
}

const GlobalStyle = createGlobalStyle`
  *{
    margin:0;
    padding:0;
    box-sizing:border-box;
  }
  body {
    font-family:'Open Sans', sans-serf;
    color:#262626;
    background:#fff;
  }
`

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}


export default Layout